import React from "react";
import { BottomNav } from "../../components/common";
import { Link } from "react-router-dom";
import "./index.less";

import RiskTable from "../../assets/images/risk-parameters.png";

const AssetRiskAnalysis = () => {
  return (
    <div>
      <h2>Asset risk analysis</h2>
      <p>
        The Commodo platform analyses and evaluates each whitelisted asset to ensure the platform's security and minimize the risks and attack vectors based on:
      </p>
      <ul>
        <li><b>Market Risk (Quantitative):</b> Measures the liquidity and volatility of the asset.</li>
        <li><b>Project Risk (Qualitative):</b> These include the thoroughness and quality of audits performed on the project, time since the project launch, reputation, and integrity of the team behind the project.</li>
      </ul>
      <p>
        After assessing each of these parameters, each asset is given a <b>weighted average score- WS</b> and the following parameters are set up for each asset/asset class:
      </p>

      <ul>
        <li>LTV, liquidation Threshold, and Liquidation Bonus</li>
        <li>Interest rate parameter of assets</li>
        <li>Exposure, the exposure limit is determined as per the WS.</li>
        <li>Collateral assets: categorise assets that are accepted as collateral in the platform.</li>
      </ul>

      <h3>Risk parameters</h3>

      <img alt={RiskTable} src={RiskTable} />

      <p className="mt-3">
        Detailed blog about Risk Assessment on Commodo 
        <Link className="link-tag ml-1" to={{ pathname: "https://blog.comdex.one/risk-assessment-on-commodo-6efcfb7fdd3e" }} target="_blank">
            <b>Click here</b>
        </Link>
      </p>
      <BottomNav
        preNavLink="risk-framework"
        prevNavText="Risk framework"
        nextNavLink="reserve-pool"
        nextNavText="The Reserve Pool"
      />

    </div>
  );
};

export default AssetRiskAnalysis;