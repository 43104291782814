import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

const RiskAssessment = () => {
  return (
    <div>
      <h2>Risk Assessment</h2>
      <p>
        Commodo runs on long-studied mechanisms designed to mitigate and endure the platform's fundamental risks and attack vectors. Every whitelisted asset on Commodo is evaluated and analysed based on an asset risk analysis framework. Interest rates for these assets are then determined, which can be modified or recalibrated through governance proposals. 
      </p>

      <p>
        In reality, no protocol is risk-free. So as additional insurance to protect the protocol and its users in case of any unknown attacks/circumstances, the protocol maintains a Reserve pool and an Emergency shutdown protocol.
      </p>

      <p>
        The following sections explain these frameworks and mechanisms in detail.
      </p>

      <BottomNav
        preNavLink="e-mode"
        prevNavText="E-mode"
        nextNavLink="asset-risk-analysis"
        nextNavText="Asset risk analysis"
      />

    </div>
  );
};

export default RiskAssessment;