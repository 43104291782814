import React from "react";
import { BottomNav } from "../../components/common"
import "./index.less";

import MachanisImg from "../../assets/images/image1.png";

const Mechanism = () => {
  return (
    <div>
      <h2>Mechanism</h2>
      <p>
        With the goal of building a lending borrowing protocol that is secure and easy to use, we designed a new mechanism: isolated money markets with bridged assets. 
      </p>
      <h2>Isolated money markets with bridged assets:</h2>
      <p>
        In Commodo we have isolated money markets that are connected through two bridged assets. Users can create a money market for any token asset they want, by pooling it with the preset bridged assets(CMST and ATOM), such money markets are called modules. A module will have a paired asset and the two bridged assets. For example, there will be modules like OSMO-CMST-ATOM, CMDX-CMST-ATOM, ETH-CMST-ATOM etc. 
      </p>

      <p>
       <b>The bridged assets:</b> CMST and ATOM. All transactions are routed through the bridged assets; therefore, these two assets need to be trusted. 
      </p>

      <p>
        In case if any token is compromised or exploited, the only ones affected are the asset suppliers of that particular pool, hence, isolating the risk to that pool only. <br />
        By limiting multiple parings, each asset(except the bridged assets) on Commodo will have only a single money market, thereby, not fragmenting the liquidity. By isolating the different money markets, the risk is also isolated, enabling a high-security level by design. 
      </p>

      <p>
      Commodo was designed to address the primary pain points of existing lending protocols: 
      </p>

      <p className="pl-5">
        - The security flaws of shared pools, addressed by isolating each module <br />
        - The problem of fragmented liquidity between too many different pools, while this      model ultimately achieves high levels of security relative to shared pools, it comes at the expense of efficiency. Commodo enables deeper liquidity and improved efficiency with the isolated money markets and bridged assets.  
      </p>

      <h2>Lending-Borrowing from a module</h2>
      <p>
        To borrow assets from any module, the user deposits the paired asset(or the bridged assets) to the module and borrows the desired asset as per LTV of deposited asset. 
      </p>

      <h2>Lending-Borrowing across modules</h2>
      <p>
        The design itself is straightforward, when a supplier uses their collateral to borrow assets from other modules, the underlying process requires creating two positions as shown below:
      </p>

      <img alt={MachanisImg} src={MachanisImg} />

      <p className="mt-2">
        Here, you can see how token A borrows token B by routing the bridged asset. On depositing token A in to module A, the system borrows an equivalent amount of the bridged asset(either CMST or ATOM depending on the market conditions) and routes it to the module B and deposits it there, and then an equivalent amount of token B is borrowed.
      </p>

      <BottomNav 
        preNavLink="commodo-platform"
        prevNavText="The Commodo platform"
        nextNavLink="interest-rates"
        nextNavText="Interest rates"
      />

    </div>
  );
};

export default Mechanism;
