import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

const EmergencyShutdown = () => {
  return (
    <div>
      <h2>Emergency Shutdown </h2>
      <p>
        <i>Emergency Shutdown</i> is triggered in the case of system upgrades or serious emergencies, such as hacking or security breaches. Once Emergency Shutdown is initiated, the prices for all collateral types in the system are immediately frozen along with other features of the platform, including lending, borrowing, repaying, and withdrawing of assets. Based on the severity of the attack, the platform will take steps to safeguard the interests of the users and the platform.
      </p>

      <BottomNav
        preNavLink="reserve-pool"
        prevNavText="The Reserve Pool"
        nextNavLink="lending-assets"
        nextNavText="Lending assets"
      />

    </div>
  );
};

export default EmergencyShutdown;