import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

import LiquidationMechanismimg from "../../assets/images/image4.png";
import HFImage from "../../assets/images/hf-image.png";
import HFImage2 from "../../assets/images/hf-image2.png";
import HFImage3 from "../../assets/images/hf-image3.png";
import AuctionImage from "../../assets/images/auction-img.png";
import AuctionImage2 from "../../assets/images/auction-img2.png";
import { Link } from "react-router-dom";

const LiquidationMechanism = () => {
  return (
    <div>
      <h2>Liquidation mechanism</h2>

      <p>
        A protocol's liquidity refers to the capital available for business operations such as borrowing funds or redeeming cTokens. Interest rates must be modelled to minimize liquidity risk and maintain an optimal utilization rate in the platform.
      </p>

      <p>
        On Commodo, liquidations are triggered when a user’s collateral does not sufficiently cover the outstanding loans. This happens when the borrower's Health factor (H.F) drops below 1. The Health factor is calculated by dividing the product of collateral's value and its Liquidation Threshold by the value of borrowed assets.
      </p>

      <div className="text-center">
        <img className="w-50 mx-auto" src={HFImage} alt={HFImage} />
      </div>

      <p>
        A user's position has liquidation threats when either of the following happens: <br />
        - The user's collaterals' value decreases  <br />
        - The user's borrowed assets' value increases  <br />
      </p>

      <p>
        In either case, if the user cannot raise their position's Health factor back to 1, the position is then sent for auction. The platform calculates the amount of debt the user owes, and that debt is repaid through auction. Anyone can repay the borrowed amount to unlock the collateral and earn a liquidation bonus. A liquidation penalty is also charged from the user's collateral if a position is sent for auction. The liquidation bonus and the liquidation penalty depend on the asset used as collateral. 
        <Link to={{ pathname: 'https://docs.commodo.one/risk-framework' }} target="_blank">
          (Refer to the Risk Assessment section)
        </Link>
      </p>

      <p>
        The Health factor of a position is displayed in the My Home section under the Borrow tab of Commodo. To avoid liquidations, you can raise your H.F by increasing the collateral deposited or repaying a part of the borrowed asset. All positions currently in the auction are displayed in the Auction section of Commodo. 
      </p>

      <h3>Auctions:</h3>
      <p>
        Auctions on Commodo follow a Dutch auction model, with instant settlements. The auction starts at an initial price which keeps on decreasing with time. The initial price is the liquidation price multiplied by a <i>buf</i> parameter. <i>Buf</i> is a configurable percentage (usually 15%). During the auction, as time passes, the bid price decreases, as shown in the figure below: 
      </p>

      <img src={LiquidationMechanismimg} />

      <p className="mt-2">
        Once the auction starts, at any time, "t," users can bid for the collateral as per the bidding price. Users can then buy the collateral at a price that they find profitable compared to the external market price of the collateral. 
      </p>

      <p>
        Auctions have 5 hours during which the debt is to be repaid. Outstanding debt is repaid through further auctions until the outstanding debt is entirely repaid.  
      </p>

      <p>
        The auction stops once the position is healthy, i.e., its HF {">"} or equal to 1 (after the debt repayment). The remaining portion of the user's collateral remains and can continue to be utilized as collateral on the protocol. 
      </p>

      <p>
       The Dutch auction resets on two conditions:  <br />
       - When the auction runs for a sufficiently long time without the collateral liquidation <br />
       - The collateral falls below a cusp value (this value is defined via governance, by default 70% of the price)
      </p>

      <p>
        In such a scenario, the platform will recalculate the user's debt to the protocol and restart the auction at a new price. 
      </p>

      <p>
        <b>For example:</b> if User A is borrowing $OSMO with $CMDX as collateral. <br />
        If $1000 $CMDX is used to borrow $500 OSMO (Max borrowable amount)
      </p>

      <p>
        $CMDX parameters: <br />
        Max LTV 50%,  <br />
        Liq. Threshold 55%, <br />
        Liq. Penalty 5%,  <br />
        Liq. bonus 5% 
      </p>

      <p>
        We calculate H.F for this position by using the formula:
      </p>

      <div className="text-center">
        <img className="w-50 mx-auto" src={HFImage} alt={HFImage} />
      </div>

      <div className="text-center">
        <img className="mx-auto" style={{ width: 200 }} src={HFImage2} alt={HFImage2} />
      </div>

      <p>
        Now if the Collateral’s value decreases to $800 (due to any reason)
      </p>

      <div className="text-center">
        <img className="mx-auto" style={{ width: 200 }} src={HFImage3} alt={HFImage3} />
      </div>

      <p>
        Hence, the position is sent for Auction. <br />
        To calculate how much of the borrowed asset needs to be repaid we have the following formula:  
      </p>

      <div className="text-center">
        <img className="mx-auto" src={AuctionImage} alt={AuctionImage} />
      </div>

      <p>
        On solving for “X” in the above equation we can determine the minimum amount of OSMO that needs to be repaid to raise the H.F back to 1.
      </p>

      <p>
        For the above example, on solving for “X” we get{" X >= 151.8"}
      </p>

      <ul>
        <li>
         - Hence, a minimum of $152 worth of OSMO needs to be repaid to bring the position’s H.F back to 1. 
        </li>
        <li>
         - In return, $167.2 worth of CMDX will be released, which includes the Liq. penalty and Liq. bonus. 
        </li>
      </ul>

      <p>To conclude, the Bidder </p>
      <ul>
        <li>- Repays $152 worth of OSMO</li>
        <li>- Gets $152 + $7.6 worth of CMDX (including Liq. Bonus)</li>
        <li>- Remaining $7.6 (Liq. Penalty) worth of CMDX is sent to the Commodo Reserve</li>
      </ul>

      <div className="text-left mt-2">
        {/* <img className="w-50" src={AuctionImage2} alt={AuctionImage2} /> */}
        
        <div className="d-flex formulatext">
          New &nbsp; <i>H.F</i> &nbsp; of the position will be: &nbsp; <i>H.F </i> &nbsp; =  &nbsp;<span className="text-center"><div className="hf-divider"> (800-167.2) x 55%</div> <div>500 - 152</div>
            <br />
            <span className="text-left"><i>H.F</i> &nbsp; =  &nbsp;<span className="text-center"> 1.00011 </span></span>
          </span>
        </div>
        <div className="d-flex formulatext">
          
        </div>
      </div>

      <p className="mt-3">
        The position is now healthy again. Now User A can choose to keep the position or close it.
      </p>

      <BottomNav
        preNavLink="interest-rates"
        prevNavText="Interest rates"
        nextNavLink="rewards"
        nextNavText="Rewards"
      />

    </div>
  );
};

export default LiquidationMechanism;
