import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

const BorrowingStrategies = () => {
  return (
    <div>
      <h2>Borrowing Strategies </h2>
      <p>
        Coming soon…
      </p>

      <BottomNav 
        preNavLink="Faq"
        prevNavText="FAQs"
        nextNavLink="glossary"
        nextNavText="Glossary"
      />

    </div>
  );
};

export default BorrowingStrategies;
