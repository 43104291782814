import React from "react";
import { Link } from "react-router-dom";
import { BottomNav } from "../../components/common";
import "./index.less";

import RewardImage from '../../assets/images/reward.png';
import RewardImage2 from '../../assets/images/reward2.png';

const Rewards = () => {
  return (
    <div>
      <h2>Boosted rewards</h2>
      <p>
        To be eligible for Boosted rewards, a user must have provided liquidity on the stCMDX-stATOM Master pool on cSwap. Depending on the amount deposited in the Master pool, users will earn Boosted rewards on Commodo for borrowing assets.
      </p>
      <p>
        Boosted rewards APR for borrowing assets are displayed in the Market cards as shown below
      </p>

      <img className="mb-3" src={RewardImage2} alt={RewardImage} />

      <p>
        Let's say Boosted rewards are enabled for borrowing $CMST and $ATOM. Incentives for these two actions are determined dynamically depending on the Min of $CMST/$ATOM borrowed from each cPool and the liquidity provided in the Master pool on cSwap by $CMST/$ATOM borrowers. Rewards will be distributed in $CMDX tokens. Boosted rewards are directly sent to the user's wallet every day at a specific time.
      </p>

      <p>
        For better understanding let's consider an example; if a user has deposited $100 in the cSwap master pool, they are eligible for Distribution rewards for up to $100 on each borrowed position.
      </p>

      <p>
        The following table will help to understand the rewards structure better:
      </p>

      <img src={RewardImage} alt={RewardImage} />

      <BottomNav
        preNavLink="liquidation-mechanism"
        prevNavText="Liquidation mechanism"
        nextNavLink="collateral-type"
        nextNavText="V2 Features"
      />

    </div>
  );
};

export default Rewards;