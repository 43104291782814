import React from "react";
import { Link } from "react-router-dom";
import { BottomNav } from "../../components/common";
import "./index.less";

const Faq = () => {
  return (
    <div className="fa-section">
      <h2>FAQs</h2>
      <h4>1. What is Commodo?</h4>
      <p>
        Commodo is a decentralised, open-source, IBC native lending and borrowing platform. Depositors can lend out assets to the platform and earn passive income. Borrowers can borrow assets in an over-collateralized manner, paying a borrowing interest. 
      </p>

      <h4>2. How do I interact with the Commodo platform?</h4>
      <p>
         To interact with the Commodo platform, connect your Keplr wallet (or any Cosmos ecosystem compatible wallet) to the platform. Once the wallet is connected, you can deposit your preferred asset. After depositing, you will earn passive income based on the market's supply-demand. You can use the deposited assets as collateral (overcollateralized as per the LTV of the deposited asset) to borrow assets from the platform by paying a borrowing interest. Any interest you earn by depositing funds helps offset the interest rate you accumulate by borrowing. 
      </p>

      <h4>3. Why borrow on Commodo?</h4>
      <p>
        - To take a position; long (Lend) or short (Borrow) <br />
        - Margin trades <br />
        - Earn rewards <br />
        - To capitalise on arbitrage opportunities <br />
        - Low transaction/trading fees <br />
      </p>

      <h4>4. What is the cost of interacting with the Commodo platform?</h4>
      <p>
        Interacting with the platform requires transactions and so transaction fees for Comdex Blockchain usage, which depend on the network status and transaction complexity. Payable in CMDX tokens. Generally very low. 
      </p>

      <h4>5. Where are my deposited funds stored?</h4>
      <p>
        Your funds are allocated in a smart contract. The smart contract code is public, open-source, formally verified, and audited by third-party auditors. You can withdraw your funds from the pool on-demand.
      </p>

      <h4>6. Is there any risk?</h4>
      <p>
        Commodo is designed to minimize risks, but no platform can be considered entirely risk-free. The risks related to the Commodo platform are the smart contract risk (risk of a bug within the platform code) and liquidation risk (risk on the collateral liquidation process). Every possible step has been taken to minimize the risk as much as possible. The platform code is public and open source. Additionally, there will be a bug bounty campaign running. You can find more on risks and security in the Risk framework section.
      </p>

      <h3>Deposit</h3>

      <h4>7. Supplying assets to the Commodo platform</h4>
      <p>
        Steps: <Link className="link-tag" to="/lending-assets"><b>Lending assets on Commodo</b></Link>
      </p>

      <h4>8. How much will I earn?</h4>
      <p>
        Depositors will receive continuous earnings as per the market conditions, and they share the interest paid by the borrowers corresponding to the average borrow rate times the utilization rate. This means that the higher the utilization of an asset, the higher the yield for depositors. Each asset has its supply-demand and corresponding APYs.
      </p>

      <h4>9. How do I withdraw?</h4>
      <p>
        To withdraw, you need to go to the My Home section and click on the withdraw button corresponding to the asset you wish to withdraw. Select the amount to withdraw and submit the transaction. You would need to ensure there is enough liquidity (not borrowed) to withdraw. If this is not the case, you would need to wait for more liquidity from depositors or borrowers repaying. 
      </p>

      <h4>10. Can I opt out of my asset from being used as collateral?</h4>
      <p>
        	Yes. After depositing your assets, you can unselect the asset so it will not be used as collateral. The opt-out option is available in the My Home section of Commodo. You can also do this when depositing the asset onto the platform. You will find this option in the top right corner of your depositing screen.
      </p>

      <h3>Borrow</h3>

      <h4>11. Borrowing assets from the Commodo platform</h4>
      <p>
       Steps: <Link className="link-tag" to="/borrowing-assets"><b>Borrowing assets on Commodo</b></Link>
      </p>

      <h4>12. Why would I borrow instead of selling my assets?</h4>
      <p>	
        Selling your assets means you are closing your position on that asset. If you are long on a particular asset, there is no point in selling it. Instead, you can use that asset as collateral to borrow other assets, speculate on its price movements, and/or obtain liquidity without selling your assets.
      </p>

      <h4>13. How much can I borrow?</h4>
      <p>	
        The maximum amount you can borrow depends on the amount you have deposited and the liquidity available. You can't borrow an asset if there isn't enough liquidity or if your health prevents you from doing so. In the Risk Assessment section, you'll find every available collateral and its borrowing parameters.
      </p>

      <h4>14. What asset do I need to repay?</h4>
      <p>
        You repay your loan on the same asset you borrowed. For example, if you borrow 100 CMST, you will pay back 100 CMST + interest accrued.
      </p>

      <h4>15. How much interest would I pay?</h4>
      <p>
        The borrowing rate, determined by the asset's supply and demand ratio, determines the interest rate you pay for borrowing assets. You can find your current borrowing rate at any time in the Borrowings section of your My Home page.
      </p>

      <h4>16. What is the health factor?</h4>
      <p>	
        The health factor is a numerical representation of the safety of your deposited assets in comparison to the borrowed assets' underlying value. The higher the value, the more secure your funds are in the event of a liquidation. Health factor is calculated by multiplying the Collateral's value with its Liquidation Threshold upon the value of borrowed assets.
      </p>

      <h4>17. When do I have to repay the loan?</h4>
      <p>	
        There is no fixed time period here. You can borrow for an indefinite period if your position is secure. However, as time goes by, the accrued interest will grow, lowering your health factor and making liquidation more probable.
      </p>

      <h4>18. How do I pay back the loan?</h4>
      <p>	
        	To pay back the loan, you simply go to the Borrowings section on your My Home page and click on the Repay button for the asset you borrowed and want to repay. Select the amount to pay back and confirm the transaction.
      </p>

      <h3>Liquidations</h3>

      <h4>19. When does your position get liquidated? What is the liquidation penalty?</h4>
      <p>
        Liquidation is triggered when your Health Factor is less than one; this happens when your collateral cannot cover the borrows. Usually occurs when the collateral asset's value decreases or when the borrowed asset's value increases. The Health Factor is displayed on the My Home page of Commodo under the Borrow tab. <br />
        The liquidation penalty is determined for each asset on Commodo, and you can see it in the risk assessment section.
      </p>

      <h4>20. Can I participate in the liquidation process?</h4>
      <p>
        Yes, anybody can participate in the liquidation process. You can navigate to the Liquidation page in Commodo and find positions to be liquidated. You can repay the borrowed asset to get the collateral at a discounted rate. 
      </p>

      <h4>21. How do I avoid liquidations?</h4>
      <p>
        To avoid liquidation, repay the loan or deposit more assets to increase your health factor. Repaying the loan would improve your health factor more than the other alternative.
      </p>

      <h3>Governance</h3>

      <h4>22. What do you need to vote?</h4>
      <p>
        You need to have CMDX tokens to participate in governance voting.
      </p>

      <h4>23. How do I vote?</h4>
      <ul>
        <li> Go to the "Governance" section in Commodo and select the relevant proposal.</li>
        <li> Read through the proposal and ensure you fully understand what is being proposed. If you would like to discuss it, select the discussion link on the right-hand side to be directed to the post on the governance forum. </li>
        <li> On the right-hand side, select the vote you want to cast.</li>
        <li> Confirm your vote.</li>

      </ul>

      <BottomNav
        preNavLink="developers-docs"
        prevNavText="Security and Developer Docs"
        nextNavLink="borrowing-strategies"
        nextNavText="Borrowing Strategies"
      />

    </div>
  );
};

export default Faq;