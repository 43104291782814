import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

import ArcImage from '../../assets/images/image1.png';

const Architecture = () => {
  return (
    <div>
      <h2>Architecture</h2>
      <p>
        The architecture of Commodo aims to create money markets with deep liquidity, high efficiency, and security. Commodo’s model ensures that impacts of volatilities in single assets remain isolated across cPools without harming the overall health of the protocol. Transit assets serve the role of bridging isolated money market cPools. 
      </p>

      <h3>cPools (connected Pools):</h3>
      <p>
        Isolated money markets are bridged through transit asset(s). Users can create money markets for any asset by pooling it with a transit asset. On Commodo, these assets are preset to be $CMST and $ATOM. Money markets on Commodo with either $CMST or $ATOM as the transit asset are called cPools. Every cPool contains paired assets and two transit assets. For example, an asset like $OSMO would have an OSMO-ATOM-CMST cPool. 
      </p>

      <p>
        cPools are therefore not exposed to the security flaws associated with impacts of volatility on the protocols following shared pool models.
      </p>

      <h3>The transit assets:</h3>
      <p>
        $CMST and $ATOM will be common assets across all cPools, which allows the isolated money markets to remain connected through two transit assets. All cross-pool borrowing routes through the transit assets; therefore, the two assets need to be amply liquid. 
      </p>

      <p>
        The transit assets ensure limits on multiple asset pairings, which helps prevent challenges caused by liquidity fragmentation in isolated lending pool models.
      </p>

      <h3>Lending-Borrowing from a cPool</h3>
      <p>
        To borrow assets from any cPool, users must deposit the transit asset to the cPool and borrow the desired asset as per the defined maximum LTV (loan-to-value) ratio of the deposited asset. 
      </p>

      <h3>Lending-Borrowing across cPools</h3>
      <p>
        Suppliers can borrow assets across cPools with their supplied assets acting as collateral for borrowing a <i>transit asset</i>. The borrowed <i>transit asset</i> then gets deposited as collateral into the cPool of the desired borrowing asset. 
      </p>

      <img src={ArcImage} alt={ArcImage} />

      <p>
        As shown above, a user deposits token A as collateral and effectively borrows token B by routing through transit assets. On depositing token A into cPool A, the system borrows an equivalent amount of the transit asset(either CMST or ATOM depending on the market conditions), routes it to cPool B, and deposits it there. Then an equivalent amount of token B is borrowed.
      </p>

      <BottomNav
        preNavLink="commodo-platform"
        prevNavText="The Commodo platform"
        nextNavLink="interest-rates"
        nextNavText="Interest rates"
      />

    </div>
  )
};

export default Architecture;
