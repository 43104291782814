import React, { useState } from "react";
import SvgSprite from "./utils/SvgSpriteLoader";
import { Layout, Button } from "antd";
import SideBar from "./components/layout/SideBar";
import NavigationBar from "./components/layout/NavigationBar";
import { useMediaQuery } from "react-responsive";
import "./App.less";
import { SvgIcon } from "./components/common";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import ScrollToTop from './ScrollToTop';

//Svg Sprite
import svgFile from "./assets/images/svg/svg-sprite.svg";

import Introduction from "./containers/Introduction";
import Faq from "./containers/Faq";
import CommodoPlatform from "./containers/CommodoPlatform"
import Rewards from "./containers/Rewards";
import Governance from "./containers/Governance";
import Glossary from "./containers/Glossary";
import Mechanism from "./containers/Mechanism";
import InterestRates from "./containers/Interest-rates";
import AssetRiskAnalysis from "./containers/Asset-risk-analysis";
import TheReservePool from "./containers/The-Reserve-Pool";
import EmergencyShutdown from "./containers/Emergency-Shutdown";
import LiquidationMechanism from "./containers/Liquidation-mechanism";
import Home from "./containers/Home";
import Architecture from "./containers/Architecture";
import HowCommodoDifferent from "./containers/HowCommodoDifferent";
import RiskAssessment from "./containers/Risk-assessment";
import SecurityDeveloperDocs from "./containers/Security-DeveloperDocs";
import BorrowingStrategies from "./containers/Borrowing-Strategies";
import LendingAssets from "./containers/LendingAssets";
import WithdrawingAssets from "./containers/WithdrawingAssets";
import BorrowingAssets from "./containers/BorrowingAssets";
import RepayingAssets from "./containers/RepayingAssets";
import BiddingforAssets from "./containers/BiddingforAssets";
import CollateralType from "./containers/CollateralType";
import Emode from "./containers/Emode";

const { Header, Content, Sider, Footer } = Layout;

const App = () => {
	const [collapsed, setCollapsed] = React.useState(false);
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const [isOpen, setIsOpen] = useState(!!isMobile);
	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
		setIsOpen(!isOpen);
	};
	return (
		<>
			<SvgSprite url={svgFile} />
			<Router onUpdate={() => window.scrollTo(100, 100)}>
				<Layout>
					<Header className="header">
						<div className="header-inner">
							<Button
								className="menu-link"
								type="link"
								onClick={toggleCollapsed}
								style={{ marginBottom: 16 }}
							>
								{collapsed ? (
									<SvgIcon
										name="chevron-right"
										viewbox="0 0 5.333 9.333"
									/>
								) : (
									<SvgIcon
										name="chevron-left"
										viewbox="0 0 5.333 9.333"
									/>
								)}
							</Button>
							<NavigationBar />
						</div>
					</Header>
					<Layout className="main-content">
						<>
							<Sider
								width={290}
								collapsible
								breakpoint="lg"
								className="commodo-sider"
								collapsed={isOpen}
								trigger={null}
							>
								<SideBar />
							</Sider>
							<Content className="right-content-wrapper">
								<ScrollToTop>
								<Switch>
									<Route exact path="/" component={Home} />
									<Route exact path="/introduction" component={Introduction} />
									<Route path="/commodo-different" component={HowCommodoDifferent} />
									<Route path="/Faq" component={Faq} />
									<Route path="/commodo-platform" component={CommodoPlatform} />
									<Route path="/architecture" component={Architecture} />
									<Route path="/mechanism" component={Mechanism} />
									<Route path="/interest-rates" component={InterestRates} />
									<Route path="/liquidation-mechanism" component={LiquidationMechanism} />
									<Route path="/rewards" component={Rewards} />
									<Route path="/risk-assessment" component={RiskAssessment} />
									<Route path="/asset-risk-analysis" component={AssetRiskAnalysis} />
									<Route path="/reserve-pool" component={TheReservePool} />
									<Route path="/emergency-shutdown" component={EmergencyShutdown} />
									<Route path="/lending-assets" component={LendingAssets} />
									<Route path="/withdrawing-assets" component={WithdrawingAssets} />
									<Route path="/borrowing-assets" component={BorrowingAssets} />
									<Route path="/repaying-assets" component={RepayingAssets} />
									<Route path="/bidding-for-assets" component={BiddingforAssets} />
									<Route path="/governance" component={Governance} />
									<Route path="/developers-docs" component={SecurityDeveloperDocs} />
									<Route path="/glossary" component={Glossary} />
									<Route path="/collateral-type" component={CollateralType} />
									<Route path="/e-mode" component={Emode} />
									<Route path="/borrowing-strategies" component={BorrowingStrategies} />
								</Switch>
								</ScrollToTop>
								<Footer className="main-footer">
									© 2022 commodo All rights reserved.
								</Footer>
							</Content>
						</>
					</Layout>
				</Layout>
			</Router>
		</>
	);
};

export default App;
