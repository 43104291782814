import React from "react";
import { Link } from "react-router-dom";
import { BottomNav } from "../../components/common";
import "./index.less";

const Emode = () => {
  return (
    <div>
      <h2>E-mode</h2>
      
       <p>
        The E-mode feature maximizes capital efficiency when collateral and borrowed assets have correlated prices. For example, DAI, USDC, CMST are all stablecoins pegged to USD, these stablecoins are all within the same E-mode category. Accordingly, a user supplying CMST in E-mode will have higher collateralization power when borrowing assets like USDC or DAI. <br />
        ATOM and stATOM is an other example. Only assets of the same category can be borrowed in E-mode. 
       </p>

       <p>
        Each category can have risk parameters’ configured independently, allowing users to have higher collateralization and enhanced borrowing power when borrowing assets within a category. 
       </p>

       <p>
        E-mode does not restrict the usage of other assets as collateral. Assets outside of the E-mode category can still be supplied as collateral with normal LTV and liquidation parameters.
       </p>

       <h3>How are E-mode pairs created? Are they governance-gated?</h3>
       <p>
        E-mode pairs are created after careful evaluation of assets and is governance gated.
       </p>

      <h3>How to enter E-mode?</h3>
      <p>
        From Markets tab users can click on E-mode button at top right to enter E-mode markets. <br />
        Deposit required asset to borrow its e-pair with a high efficiency. 
      </p>

      <h3>How to exit E-mode?</h3>
      <p>
        From My Home, you can edit your E-mode positions. <br />
        From E-mode button on the Markets page also users can edit their position by repaying the debt and withdraw the locked collateral. 
      </p>

      <h3>How you can leverage E-mode?</h3>
      <p>
        Lets say you have $100 worth of stATOM. You can use E-mode to borrow ATOM at high LTVs, lets say 90%. If transaction is successful you get $90 worth of ATOM. Stake this ATOM, get $90 worth of stATOM and deposit it on E-mode again borrow more ATOM and keep repeating this process to earn boosted yields on your position!
      </p>

      <BottomNav
        preNavLink="collateral-type"
        prevNavText="Collateral Type"
        nextNavLink="risk-assessment"
        nextNavText="Risk Assessment"
      />

    </div>
  )
};

export default Emode;
