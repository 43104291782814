import React from "react";
import { Link } from "react-router-dom";
import { BottomNav } from "../../components/common";
import "./index.less";

const CollateralType = () => {
  return (
    <div>
      <h2>Collateral Type</h2>
      <p>
        Every collateral on Commodo will have a classification depending on its Risk assessment. This classification helps to mitigate risks to the platform arising from new and volatile asset classes.
      </p>
      <p>
        Collaterals on Commodo will be classified as Isolated and Normal collaterals;
      </p>
      <p>
        Isolated Collateral; the Isolated collateral assets have limited borrowing power and have a specific supply cap. They can only open one borrow position at a time, ie no multiple borrows using these assets. 
        <br />
        Normal Collateral; can open one or multiple borrow positions within the Max ltv of the asset.
      </p>
      <h3>How do I know if an asset is an Isolated type? </h3>
      <p>
        When you are lending or borrowing using the asset you can see if the asset is isolated or normal by checking the “Collateral Details” section of the corresponding page. 
      </p>
      <h3>Can I open multiple borrow positions with an isolated asset?</h3>
      <p>
        No, you can open only one position with an isolated asset at a time. 
      </p>
      
      <BottomNav
        preNavLink="rewards"
        prevNavText="Boosted rewards"
        nextNavLink="e-mode"
        nextNavText="E-mode"
      />

    </div>
  )
};

export default CollateralType;
