import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

import ReservePoolImg from '../../assets/images/Reserve-Pool.png';

const TheReservePool = () => {
  return (
    <div>
      <h2>The Reserve Pool</h2>
      <p>
        The reserve pool will act as insurance in case of any unknown attack/circumstances badly affecting the platform. A portion of the interest paid by borrowers goes to the reserve pool (based on the reserve factor of the asset). 
      </p>
      <p>
        The Commodo Treasury receives half of the Reserve pool funds, while the remaining half is utilized to buy back CMDX tokens, these are distributed to CMDX stakers.
      </p>
      <img src={ReservePoolImg} alt="The Reserve Pool" />
      <p className="mt-3">
       The reserve pool funds are used only after governance votes. The motivation behind this is to allow the reserve to act as the lender-of-last-resort in case of a bank run. 
      </p>

      <BottomNav
        preNavLink="asset-risk-analysis"
        prevNavText="Asset risk analysis"
        nextNavLink="emergency-shutdown"
        nextNavText="Emergency Shutdown"
      />

    </div>
  );
};

export default TheReservePool;
