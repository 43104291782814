import React from "react";
import { Link } from "react-router-dom";
import { BottomNav } from "../../components/common";
import "./index.less";

const Introduction = () => {
  return (
    <div>
      <h2>Introduction</h2>
      <p>
        One of the critical components of global finance is debt markets and the ability to lend, borrow against collateral assets. The advent of DeFi was a significant milestone in the evolution of crypto-economies, where holders of crypto assets could unlock liquidity on their long-term holdings by taking leveraged exposure to crypto-assets.
      </p>

      <p>
        Within DeFi, we have seen lending-borrowing models evolve from P2P lending to pooled lending. In the former, individuals can issue loans to one another by taking direct exposure to the counterparty risk of the borrower. In contrast, in the latter, lenders can pool their assets into a shared lending pool, which is then lent out to various borrowers, effectively spreading the counterparty risk across the entire pool rather than to individuals. Each model has its risks and benefits, serving participants based on their risk appetites in the markets they choose to take exposure.
      </p>

      <p>
        One key aspect of the lending-borrowing dynamics in markets is the type and size of collateral assets that borrowers must post to access debt from lenders or a lending pool. With several collateral types, protocols must consider risk metrics for each asset in proportion to the size of the protocol's exposure to the asset. Often, large movements and volatilities in markets of single assets can have cascading impacts on the overall collateral and lending pools of the protocols, which in turn can adversely impact the protocol's health. The community needs a reliable borrowing and lending platform. Comdex is introducing Commodo - A seamless borrowing and lending platform.
      </p>

      <p>
        Commodo is a decentralised, open-source, IBC-native, collateralized lending-borrowing platform on Cosmos, built using the Comdex infrastructure. Any user can earn interest by depositing assets to the platform. Any user can overcollateralize assets to borrow from the platform by paying an interest rate. 
      </p>

      <p>
        Commodo is designed to minimize the risks and attack vectors. The Comdex team curated a cogent architecture design to give the users a secure, efficient, and easy-to-use platform. Commodo tries to alleviate the pain points existing in current lending borrowing markets with its characteristic architectural design. Commodo will have money markets called cPools for the top liquid assets of the Cosmos ecosystem. In the future, there will be money markets for interchain assets from Ethereum and other ecosystems.
      </p>

      <p>
        Suppliers (and borrowers) of an asset can interact directly with the platform, earning (and paying) a floating interest rate without having to negotiate terms such as maturity, interest rate, or collateral with a peer or counterparty.
      </p>

      <p>
        In the following document, we discuss the Commodo platform in detail. For further discussions, connect with our community and team.
      </p>

      <div className="into-link">
        Check out:

        <Link to={{ pathname: "https://twitter.com/Commodo_Finance" }} target="_blank">
          Twitter
        </Link>
        ||
        <Link to={{ pathname: "https://t.me/commodo_finance" }} target="_blank">
          Telegram
        </Link>
        ||
        <Link to={{ pathname: "https://discord.gg/comdexofficial" }} target="_blank">
          Discord
        </Link>
      </div>

      <BottomNav
        preNavLink="/"
        prevNavText="Home"
        nextNavLink="commodo-different"
        nextNavText="How is Commodo different"
      />

    </div>
  );
};

export default Introduction;