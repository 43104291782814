import React from "react";
import { BottomNav } from "../../components/common";
import { Link } from 'react-router-dom';
import "./index.less";

import StepImg12 from '../../assets/images/utp12.jpg';
import StepImg13 from '../../assets/images/utp13.jpg';
import StepImg14 from '../../assets/images/utp14.jpg';

const BorrowingAssets = () => {
  return (
    <div className="using-platform-section">
      <h2>Borrowing Assets</h2>

      <h4>Steps:</h4>

      <p>
        1. Follow steps 1- 4 of <Link to="/lending-assets">Lending assets on Commodo</Link>. <br />
        At this stage, you have successfully lent assets on Commodo.
      </p>

      <p>
        2. Now, navigate to the “Borrow” page of Commodo. 
      </p>

      <img src={StepImg12} alt={StepImg12} />

      <p>
        3. Click on the “Details” button of the Borrow market from which you wish to borrow funds, let's borrow OSMO. On clicking it the corresponding Borrow market will open up. You can see the current utilization of the asset and its Borrow APY and other details from this screen.
      </p>

      <p>
        4. Select Collateral Asset (all your available lend positions will be showcased) and select the Borrow Asset. Click on MAX button or input the values. Check HF and Current LTV and hit the “Borrow” button.
      </p>

      <img src={StepImg13} alt={StepImg13} />

      <p>
        5. Once you click on the “Borrow” button your wallet will pop up, click on “Approve” to complete the transaction. 
      </p>

      <p>
        6. If the transaction is successful, you will be redirected to the “My Home” page, where you can see the details of your Borrow position.
      </p>

      <img src={StepImg14} alt={StepImg14} />

      <BottomNav
        preNavLink="withdrawing-assets"
        prevNavText="Withdrawing Assets"
        nextNavLink="repaying-assets"
        nextNavText="Repaying Assets"
      />

    </div>
  );
};

export default BorrowingAssets;