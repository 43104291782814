import React from "react";
import { Link } from "react-router-dom";
import { BottomNav } from "../../components/common";
import "./index.less";

const Governance = () => {
  return (
    <div>
      <h2>Governance</h2>
      <p>
        Commodo is built to create democratized money markets for its community. The Commodo community will drive the development and growth of the Commodo platform. Governance will follow a democratic approach. 
      </p>

      <p>
        At inception, the team will shortlist a set of whitelisted assets and calibrate risk parameters to facilitate a smooth launch. As the Commodo platform matures, it will eventually transform into a DAO, with users choosing which assets to whitelist and their corresponding risk parameters.
      </p>

      <p>
        The CMDX token will be required to bring forth proposals that would be voted on to modify critical parameters such as economics, security, and the platform's development. 
      </p>

      <p>
        In the Commodo's Govern tab, users can see the existing proposals. Users can raise a new proposal by locking up some CMDX tokens. Tokens unlock after the voting period (2 days). The changes requested will then be incorporated into the platform upon due diligence if the proposal meets the minimum requirements.
      </p>

      <p>
        <b><Link className="link-tag" to={{ pathname: 'https://forum.comdex.one/' }} target="_blank">Forum:</Link></b> we have a forum to discuss proposals, functionalities, or other queries on Commodo. The team actively monitors this page, so users can leverage it to make their concerns heard or to make suggestions to improve the product and ecosystem.
      </p>

      <BottomNav
        preNavLink="bidding-for-assets"
        prevNavText="Bidding for Assets"
        nextNavLink="developers-docs"
        nextNavText="Security and Developer Docs"
      />

    </div>
  );
};

export default Governance;