import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

const HowCommodoDifferent = () => {
  return (
    <div>
      <h2>How is Commodo different from pooled lending protocols?</h2>
      <p>
        Most lending borrowing protocols have adopted a pooled lending model, where lenders' assets are pooled for borrowers to borrow at an interest rate. While this model improves protocol security, it also limits the amounts of a single borrowable asset. Protocols also differ in their model for the collateral asset pairings for lending pools. Some models permit borrowers to borrow any asset from a shared pool of lent assets by depositing any whitelisted collateral asset. On the other hand, isolated pools have pairings of collateral and lent assets. The isolated pool model reduces the impact of market volatilities on protocol health. Isolated lending pools paired with single collateral assets limit the effects of liquidations in single collateral assets on overall protocol debt.  
      </p>

      <p>
        In models with shared pools, lenders deposit their assets into a lending pool where borrowers can borrow any asset by collateralizing another whitelisted asset. In such models, however, the impact of volatilities in markets of a single asset can impact the pool's overall health. Attackers can wipe out entire pools by exploiting low liquidity market volatilities, which can impact other lenders. Models with isolated lending pools offer higher security against such vulnerabilities; however, the resulting fragmentation of asset liquidity across various lending pools limits the amount of borrowable debt in single pools. 
      </p>

      <p>
        Commodo tries to alleviate these pain points in existing lending-borrowing markets. Commodo has a distinctive architectural design with cPools and transit assets. With this design, Commodo provides money markets that are secure, efficient, and have deeper liquidity.
      </p>

      <BottomNav
        preNavLink="/introduction"
        prevNavText="Introduction"
        nextNavLink="commodo-platform"
        nextNavText="The Commodo platform"
      />

    </div>
  );
};

export default HowCommodoDifferent;