import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

import StepImg5 from '../../assets/images/utp5.jpg';
import StepImg6 from '../../assets/images/utp6.jpg';
import StepImg7 from '../../assets/images/utp7.jpg';
import StepImg8 from '../../assets/images/utp8.jpg';
import StepImg9 from '../../assets/images/utp9.jpg';
import StepImg10 from '../../assets/images/utp10.jpg';
import StepImg11 from '../../assets/images/utp11.jpg';

const WithdrawingAssets = () => {
  return (
    <div className="using-platform-section">
      <h2>Withdrawing Assets</h2>

      <h4>Steps:</h4>
      <p>
        1. Navigate to the “My Home” page and connect your wallet to Commodo by clicking the “Connect Wallet” button. Once you click on “Keplr Wallet”, your wallet should pop up, click on “Approve” to connect the wallet.
      </p>

      <img src={StepImg5} alt={StepImg5} />

      <p>
        2. Once you connect your wallet your Lend position will be displayed on this page
      </p>

      <img src={StepImg6} alt={StepImg6} />

      <p>
        3. Click on the “Withdraw” button of the position you wish to withdraw. The “Available” amount you can withdraw will be displayed here, enter a number less than it and click on the “Withdraw” button to withdraw the amount.
      </p>

      <p>
        For example, here the user is trying to withdraw 100 CMDX
      </p>

      <img src={StepImg7} alt={StepImg7} />

      <p>
        4. Once you click on the “Withdraw” button your wallet will pop up, click on “Approve” to complete the transaction. 
      </p>

      <img className="small-image" src={StepImg8} alt={StepImg8} />

      <p>
        5. If the transaction is successful, you will see the “Available” amount has decreased on the Withdraw page.
      </p>

      <img src={StepImg9} alt={StepImg9} />

      <p>
        6. Now if you wish to Close this position, click on the “Close” button at the top.
      </p>

      <img src={StepImg10} alt={StepImg10} />

      <p>
        7. On the Close page, you can see the “Available” amount you can withdraw to close the position. After checking, click on the “Close” button. The wallet will pop up, click on the “Approve” button.
      </p>

      <p>
        8. If the transaction is successful, you will be redirected to the “My Home” page, here you can verify you have successfully closed the position and the closed amount will be reflected in your wallet also.
      </p>

      <img src={StepImg11} alt={StepImg11} />

      <BottomNav
        preNavLink="lending-assets"
        prevNavText="Lending Assets"
        nextNavLink="borrowing-assets"
        nextNavText="Borrowing Assets"
      />

    </div>
  );
};

export default WithdrawingAssets;