import { List } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { SvgIcon } from "../../components/common";
import "./index.less";

const MAX_LENGTH = 60;

const data = [
  {
    icon: <SvgIcon name="intro-icon" viewbox="0 0 33.007 36.341" />,
    title: "Introduction",
    description:
      "The ability to seamlessly hold new assets gives new superpowers.",
    url: "/Introduction",
  },
  {
    icon: <SvgIcon name="protocol-icon" viewbox="0 0 36 31.5" />,
    title: "Platform",
    description:
      "Commodo is a IBC native lending borrowing protocol on Cosmos.",
    url: "/commodo-platform",
  },
  {
    icon: <SvgIcon name="riskframework-icon" viewbox="0 0 30 28.451" />,
    title: "Risk Assessment",
    description: "Commodo runs on long-studied mechanisms designed ",
    url: "/risk-assessment",
  },
  {
    icon: <SvgIcon name="using-icon" viewbox="0 0 3.143 5.189" />,
    title: "Using the platform",
    description:
      "Navigate to the “Lend” page and connect your wallet with Commodo.",
    url: "/lending-assets",
  },
  {
    icon: <SvgIcon name="faq-icon" viewbox="0 0 33.846 33.846" />,
    title: "FAQs",
    description: "Commodo is a decentralised, open-source, IBC native...",
    url: "/Faq",
  },
  {
    icon: <SvgIcon name="contracts-icon" viewbox="0 0 28.5 24.429" />,
    title: "Security and Developer Docs",
    description:
      "Lend module is used for collateralized lending-borrowing of assets",
    url: "/developers-docs",
  },
];

const Home = () => {
  const history = useHistory();
  return (
    <div>
      <h2 className="mb-0">Commodo Documentation</h2>
      <h4>A seamless borrowing and lending platform built on Comdex</h4>
      <h2 className="mb-0 mt-4">Explore Commodo</h2>
      <List
        className="home-list"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              history.push(`${item.url}`);
            }}
          >
            <List.Item.Meta
              avatar={item.icon}
              title={item.title}
              description={`${item.description.substring(0, MAX_LENGTH)}...`}
            />
          </List.Item>
        )}
      />
      <div className="bottom-cards">
        <Link to={{ pathname: "https://commodo.one/" }} target="_blank">
          <div className="card-items">
            <div className="arrow-link">
              <SvgIcon name="arrow-topright" viewbox="0 0 22.056 22.504" />
            </div>
            <SvgIcon name="commodo-icon" viewbox="0 0 73.139 39.495" />
            <h4>Take me to Commodo</h4>
            <p>
              link to redirect <br /> traffic to app
            </p>
          </div>
        </Link>
        <Link
          to={{ pathname: "https://bit.ly/ComdexOfficialDiscord" }}
          target="_blank"
        >
          <div className="card-items">
            <div className="arrow-link">
              <SvgIcon name="arrow-topright" viewbox="0 0 22.056 22.504" />
            </div>
            <SvgIcon name="discord" viewbox="0 0 29.539 22.155" />
            <h4>Discord</h4>
            <p>
              chat with community <br /> on discord
            </p>
          </div>
        </Link>
        <Link
          to={{ pathname: "https://forum.comdex.one/c/site-feedback/2" }}
          target="_blank"
        >
          <div className="card-items">
            <div className="arrow-link">
              <SvgIcon name="arrow-topright" viewbox="0 0 22.056 22.504" />
            </div>
            <SvgIcon name="help-icon" viewbox="0 0 27 31.5" />
            <h4> Found an issue?</h4>
            <p>
              improve this page by adding your <br /> suggestion
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Home;
