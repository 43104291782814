import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

const Glossary = () => {
  return (
    <div>
      <h2>Glossary</h2>
      <p>
        1. <b>Collateral:</b> Assets that can be deposited to Commodo to borrow other assets. Assets that can be used as collateral are determined by governance.
      </p>

      <p>
        2. <b>Collateral Factor:</b> Each market has a collateral factor, ranging from 0 to 1, representing the portion of the underlying asset value that can be borrowed. Illiquid, small-cap assets have low collateral factors; they do not make good collateral, while liquid, high-cap assets have high collateral factors. The sum of the value of an account's underlying token balances, multiplied by the collateral factors, equals a user's borrowing capacity.
      </p>

      <p>
        3. <b>Asset Utilisation (U):</b> Asset Utilisation is equal to the total asset borrowed from a cPool divided by the total asset deposited into the cPool.
      </p>

      <p>
        4. <b>The Loan to Value (LTV):</b> LTV ratio defines the maximum amount of currency that can be borrowed with specific collateral. LTV is expressed in percentages: at LTV=75%, for every $100 worth of collateral, borrowers will be able to borrow $75 worth of the corresponding currency.
      </p>

      <p>
        5. <b>Liquidation threshold:</b> It is the percentage at which a loan is defined as undercollateralized. For example, a Liquidation threshold of 80% means that if the value rises above 80% of the collateral, the loan is undercollateralized and could be liquidated.
      </p>

      <p>
        6. <b>Health Factor (H.F):</b> It's a numeric representation of your position's safety from liquidation. It is calculated by multiplying the Collateral's value with its Liquidation Threshold upon the value of borrowed assets. Health factors should always be greater than 1 to avoid liquidations.
      </p>

      <p>
        7. <b>Liquidation Bonus:</b> Anyone who helps in liquidation(via auctions) will get a discount on the collateral unlocked, usually around 5-10%; this is the liquidation bonus.
      </p>

      <p>
        8. <b>Liquidation Penalty:</b> Fee paid by the vault owners in case a position is undercollateralized and auctioned. 
      </p>

      <p>
        9. <b>Reserve factor:</b> Reserve factor is a percentage of the borrowed interest rate the platform keeps in a collector contract. The reserve helps to mitigate risks to the platform. 
      </p>

      <p>
        10. <b>Total value locked (TVL):</b> It is the total value of all the assets deposited on the platform.
      </p>

      <p>
        11. <b>APY:</b> Annual percentage yield means your compounded return per year as a percentage figure.
      </p>

      <p>
        12. <b>Liquidation price:</b> The price of the collateral at which the position will be sent for liquidation.
      </p>

      <p>
        13. <b>Auctioned asset:</b> The asset that is available for auction. It is the locked collateral used to borrow, whose position got liquidated.
      </p>

      <p>
        14. <b>Bidding asset:</b> The asset that must be repaid to bid for the auctioned asset.
      </p>

      <p>
        15. <b>Distribution APY:</b> it is the external rewards APY given to platform users for borrowing certain assets on Commodo. Distributed in CMDX tokens.
      </p>

      <BottomNav
        preNavLink="borrowing-strategies"
        prevNavText="Borrowing Strategies"
      />

    </div>
  );
};

export default Glossary;