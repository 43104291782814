import React from "react";
import { BottomNav } from "../../components/common";
import { Link } from "react-router-dom";
import "./index.less";

import InstRateImg from "../../assets/images/image2.png";
import InstRateImg2 from "../../assets/images/image3.png";
import InterestImg3 from "../../assets/images/inrest-table1.png";
import InterestImg4 from "../../assets/images/inrest-table2.png";
import InterestImg5 from "../../assets/images/inrest3.png";
import IndexBasedImg1 from "../../assets/images/index-based1.png";
import IndexBasedImg2 from "../../assets/images/index-based2.png";
import IndexBasedImg3 from "../../assets/images/index-based3.png";

const InterestRates = () => {
  return (
    <div>
      <h2> Interest rates </h2>
      <p>
        The interest rates on the platform are dynamic and depend on the supply and demand of the assets. Depositors earn an interest rate by lending assets to Commodo, and borrowers have to pay an interest rate to borrow assets from Commodo. The algorithmic interest rate model minimizes liquidity risk and maintains an optimal utilization rate across cPools.
      </p>

      <p>
        Based on the asset utilization, the interest increases linearly till the optimal utilization value. After which, it increases sharply (as shown below):
      </p>

      <img alt={InstRateImg} src={InstRateImg} />

      <p className="mt-3">
        ,<i>Asset Utilization (U) =total asset borrowed from cPool/ total asset deposited into cPool</i>
      </p>

      <h3>Borrowing APY</h3>

      <p>
        Utilization ratio (U) approaching 100% implies materialisation of large liquidity risks. To prevent this, the interest curve kinks around an optimal utilization rate (U-optimal)
      </p>

      <img alt={InstRateImg2} src={InstRateImg2} />

      <p className="mt-3">
       Above, Ro is the base, R1 is slope1, and R2 is slope2. These parameters are determined for each asset by an evaluation process. <Link className="link-tag" to='/risk-assessment'>(Refer to the risk assessment section)</Link>
      </p>

      <p>
        The platform does not guarantee liquidity; instead, it relies on the interest rate model to incentivize it. In periods of extreme demand for an asset, the liquidity in the platform (the tokens available to withdraw or borrow) will decline; when this occurs, interest rates rise, incentivizing supply and disincentivizing borrowing
      </p>

      <h3>Interest rate model parameters </h3>
      <p>
        Interest parameters for each asset are calibrated upon careful evaluation (see risk assessment section for more details). It is essential to distinguish assets as volatile assets that need liquidity at all times to enable instant liquidations. These volatile assets are set with a low optimal utilization rate, typically around 50%.
      </p>

      <p>
        <b>Variable interest rate model parameters:</b>
      </p>

      <img alt={InterestImg3} src={InterestImg3} />

      <p className="mt-3">Commodo offers a stable interest rate borrowing option for the bridged asset - CMST</p>

      <p><b>Stable interest rate model parameters: </b></p>

      <img alt={InterestImg5} src={InterestImg5} />

      <p>
        Users may borrow CMST with a variable or stable interest rate. The variable borrow-interest rate may change after every action (deposit, withdraw, borrow or repay) but remains constant for users who borrow with a stable interest rate. However, the stable rate for new loans may vary depending on each interaction to better suit the protocol's present condition (liquidity available, depositors APY).
      </p>

      <p>
        The stable interest rate option helps users plan their finances and predict their expenses. The stable interest rate will be fixed for a user unless rebalancing conditions are triggered. Stable rates are rebalanced as follows:
      </p>

      <ul>
        <li>
          Decreased if,  <br />
          S ≥ St + 20%
        </li>
        <li>
          Increased if, <br />
          S + 20% ≤ St or if Utilisation is ≥ 90%
        </li>
      </ul>

      <p>Where; S is the rate at which you borrowed, and St is the current stable rate in the system.</p>

      <h3 className="mt-3">Depositors APY </h3>

      <img alt={InterestImg4} src={InterestImg4} />

      <p className="mt-3">Where; Ut is the utilisation rate of the asset and Rf is the reserve factor.</p>

      <p>
        Any interest you earn by depositing funds helps offset the interest rate you accumulate on borrowing. By depositing assets to the platform, users earn Depositors APYs. On depositing funds to Commodo, users get receipt tokens called cTokens.
      </p>

      <h3>cTokens </h3>

      <p>
        cTokens, or collateral tokens, are digital assets that accrue interest over time. Similar to LP tokens, they act as a receipt for the assets deposited to Commodo. cTokens earns interest as per the lending rate earned from the cPool of the deposited asset.
      </p>

      <p>
        Users who deposit tokens into a cPool receive an equivalent amount of cTokens. These cTokens represent a claim for a portion of the cPool's total tokens. The cTokens accrue interest, meaning that the cTokens held by a user will increase over time. These cTokens are redeemable for the underlying asset, plus any accrued interest. Users can borrow from the protocol using cTokens as collateral. The borrowing power of cTokens is as per the LTV of the underlying asset. The LTV, set by governance, is regularly adjusted. <Link className="link-tag" to='/risk-assessment'>(Refer to Risk assessment section)  </Link>
      </p>

      <p>
        When a user's outstanding debt exceeds their borrowing capacity, they may be liquidated by another user in exchange for the first user's cToken collateral. This repayment will be made at a liquidation discount, deducted from the user's collateral. Commodo's cTokens, therefore, work similarly to Aave's aToken model.
      </p>

      <p>
        For example, if you deposit 1 ATOM in the Commodo's CMDX cPool, you will be minted one cATOM token. As the pool accumulates interest, your cATOM token balance will increase correspondingly. When a cATOM token holder redeems their token for ATOM, they will receive ATOM 1 plus accrued interest, say 1.045 ATOM (0.045 being the ATOM earned from the interest rate).
      </p>

      <h3>Index-Based Interest Accrual</h3>

      <p>
        Indexes are used to calculate the accrued interest on Commodo to help ease out the load on the chain and improve its efficiency. Commodo has a Global Index and a User Index. The Global interest index derives the current borrowing rate for an asset on Commodo. The platform also creates user-specific indexes to calculate the accrued interest for each user.
      </p>

      <p>
        Each asset in every cPool will have a global interest index, which gets updated with every user interaction for that asset. Also, all user positions will have a user-specific index, which is updated if the user himself makes any interactions or if another user interacts with the same asset.
      </p>

      <h4>Formulas used:</h4>

      <b>Effective rate </b> <br />

      <img style={{ maxWidth: 300 }} alt={IndexBasedImg1} src={IndexBasedImg1} />

      <p>where; t1 is the time of last interaction, t2 is the current time, borrow-rate(t1) is the borrowing rate at time t1, </p>

      <b>G index <sub>t1</sub></b> <br />

      <p>At inception, <b>G index <sub>t1</sub></b> is the base borrow rate of the asset (0.002 usually).</p>

      <p>For every interaction (say at t2 seconds), G index t1 will be updated and saved as G index t2 using the below formula.</p>

      <b>G index <sub>t2</sub></b> <br />

      <img style={{ maxWidth: 305 }} alt={IndexBasedImg2} src={IndexBasedImg2} />

      <p>Similarly, we have the user index as <b>U index <sub>t1</sub></b> which gets updated as <b>U index <sub>t2</sub></b> at the time of interaction <b>t<sub>2</sub></b>.</p>

      <b>Liability<sub>previous</sub></b> <br />

      <p>It is the amount a user is borrowing, his liability to the platform.</p>

      <b>Liability<sub>current</sub></b> <br />

      <img style={{ maxWidth: 395 }} alt={IndexBasedImg3} src={IndexBasedImg3} />

      <p>  The above formula is used to calculate a user’s current liability and the interest accrued.</p>

      <BottomNav
        preNavLink="architecture"
        prevNavText="Architecture"
        nextNavLink="liquidation-mechanism"
        nextNavText="Liquidation mechanism"
      />

    </div>
  );
};

export default InterestRates;