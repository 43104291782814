import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

import StepImg15 from '../../assets/images/utp15.jpg';
import StepImg16 from '../../assets/images/utp16.jpg';
import StepImg17 from '../../assets/images/utp17.jpg';
import StepImg18 from '../../assets/images/utp18.jpg';
import StepImg19 from '../../assets/images/utp19.jpg';

const RepayingAssets = () => {
  return (
    <div className="using-platform-section">
      <h2>Repaying assets</h2>

      <h4>Steps:</h4>

      <p>
        1. Navigate to the “My Home” page and connect your wallet to Commodo by clicking the “Connect Wallet” button. Once you click on “Keplr Wallet”, your wallet should pop up, click on “Approve” to connect the wallet.
      </p>

      <p>
        2. Once you connect your wallet, navigate to the Borrow section of My Home page, here your Borrow positions will be displayed.
      </p>

      <img src={StepImg15} alt={StepImg15} />

      <p>
        3. Click on the “Repay” button of the position you wish to repay. The “Remaining to repay” amount will be displayed here, enter a number less than it and click on the “Repay” button to repay the amount.
      </p>

      <img src={StepImg16} alt={StepImg16} />

      <p>
        4. Once you click on the “Repay” button your wallet will pop up, click on “Approve” to complete the transaction. 
      </p>
      
      <p>
        5. If the transaction is successful, you will see the “Remaining to repay” amount has decreased on the Repay page.
      </p>

      <img src={StepImg17} alt={StepImg17} />

      <p>
        6. If you want close this debt position, click on the “Close” button on top. Here you can see the amount to be repaid to close the position and the Available balance in your wallet. 
      </p>

      <img src={StepImg18} alt={StepImg18} />

      <p>
        7. Click on the “Close” button to repay the debt and close your position. Your Keplr wallet will pop up, click on the “Approve” button.
      </p>

      <p>
        8. If the transaction is successful, you will be redirected to the “My Home” page, here you can verify you have successfully closed the position and the closed amount will be reflected in your wallet also.
      </p>

      <img src={StepImg19} alt={StepImg19} />

      <BottomNav
        preNavLink="borrowing-assets"
        prevNavText="Borrowing Assets"
        nextNavLink="bidding-for-assets"
        nextNavText="Bidding for Assets"
      />

    </div>
  );
};

export default RepayingAssets;