import React from "react";
import { Link } from "react-router-dom";
import { BottomNav } from "../../components/common";
import "./index.less";

const CommodoPlatform = () => {
  return (
    <div>
      <h2>The Commodo platform</h2>
      <p>
        Commodo is an IBC native lending borrowing platform on Cosmos, built using the Comdex infrastructure. Commodo uses Comdex's modules (listed below) to deliver a secure and scalable application.
      </p>

      <ul>
        <li>
          <Link className="link-tag" to={{ pathname: "https://devdocs.comdex.one/lend" }} target="_blank">
            <b>Lend module</b>
          </Link>
        </li>
        <li>
          <Link className="link-tag" to={{ pathname: "https://devdocs.comdex.one/asset" }} target="_blank">
            <b>Asset module</b>
          </Link>
        </li>
        <li>
          <Link className="link-tag" to={{ pathname: "https://devdocs.comdex.one/bandoracle" }} target="_blank">
            <b>Oracle module</b>
          </Link>
        </li>
        <li>
          <Link className="link-tag" to={{ pathname: "https://devdocs.comdex.one/liquidation" }} target="_blank">
            <b>Liquidation module</b>
          </Link>
        </li>
        <li>
          <Link className="link-tag" to={{ pathname: "https://devdocs.comdex.one/auction" }} target="_blank">
            <b>Auctions module</b>
          </Link>
        </li>
      </ul>

      <p>
        Commodo has a robust architectural design with cPools and dynamic interest rates, complemented by an efficient liquidation mechanism, enabling it to be a top-tier lending and borrowing platform with sustainable yields. 
      </p>

      <p>
        Money markets in Commodo are called cPools. Depositors can lend Cosmos ecosystem assets and, in the future, interchain assets from Ethereum and other markets to the cPools to earn yields. Borrowers can borrow with overcollateralized loans, paying a dynamic interest rate that depends on the platform's supply and demand of assets. 
      </p>

      <p>
        Liquidations are triggered for positions when their Health Factor drops below 1. An auction of the liquidated position recovers the borrowed funds to maintain the solvency of the platform. Interest rates minimize liquidity risks and maintain optimal utilization-rates through Commodo's algorithmic model. 
      </p>

      <p>
        Commodo aims to be fully decentralised and follow democratic governance. Through proposals, users can propose the onboarding of new assets, changes in interest rate parameters of existing assets, and other major protocol management parameters. At inception, parameters calibrated and modelled by the team through careful evaluations of each asset will be applied. After due diligence, proposals must meet the minimum requirements for changes to be incorporated into the platform.
      </p>

      <BottomNav
        preNavLink="commodo-different"
        prevNavText="How is Commodo different"
        nextNavLink="architecture"
        nextNavText="Architecture"
      />

    </div>
  )
};

export default CommodoPlatform;
