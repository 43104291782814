import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { SvgIcon } from "../../common";
import { Menu } from "antd";
import "./index.less";
import Scrollbar from "react-scrollbars-custom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SideBar = (toggleCollapsedMobile) => {
  const location = useLocation();
  return (
    <div className="sidebar-inner">
      <div className="sidebar-menu">
        <Scrollbar style={{ height: 'calc(100vh - 105px)' }}>
          <Menu mode="inline">
            <Menu.Item onClick={toggleCollapsedMobile} key="m1" icon={<SvgIcon name="intro-icon" viewbox="0 0 33.007 36.341" />}>
              <NavLink
                to="/introduction"
                className={location.pathname === "/introduction" ? "selected" : ""}
              >
                Introduction
              </NavLink>
            </Menu.Item>
            <Menu.Item key="m2" icon={<SvgIcon name="faq2-icon" viewbox="0 0 3.847 5.189" />}>
              <NavLink
                to="/commodo-different"
                className={location.pathname === "/commodo-different" ? "selected" : ""}
              >
                How is Commodo different
              </NavLink>
            </Menu.Item>
            <Menu.SubMenu key="m3" title="The Commodo platform" icon={<SvgIcon name="protocol-icon" viewbox="0 0 36 31.5" />}>
              <Menu.Item key="m3-1" icon={<SvgIcon name="protocol-icon" viewbox="0 0 36 31.5" />}>
                <NavLink
                  to="/commodo-platform"
                  className={location.pathname === "/commodo-platform" ? "selected" : ""}
                >
                  The Commodo platform
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m3-2" icon={<SvgIcon name="architecture-icon" viewbox="0 0 32 32" />}>
                <NavLink
                  to="/architecture"
                  className={location.pathname === "/architecture" ? "selected" : ""}
                >
                  Architecture
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m3-3" icon={<SvgIcon name="interestrates-icon" viewbox="0 0 33.957 28.352" />}>
                <NavLink
                  to="/interest-rates"
                  className={location.pathname === "/interest-rates" ? "selected" : ""}
                >
                  Interest rates
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m3-4" icon={<SvgIcon name="liquidationmechanism-icon" viewbox="0 0 32.235 28.492" />}>
                <NavLink
                  to="/liquidation-mechanism"
                  className={location.pathname === "/liquidation-mechanism" ? "selected" : ""}
                >
                  Liquidation mechanism
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m3-5" icon={<SvgIcon name="reward-icon" viewbox="0 0 30.264 26.656" />}>
                <NavLink
                  to="/rewards"
                  className={location.pathname === "/rewards" ? "selected" : ""}
                >
                 Boosted rewards
                </NavLink>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="m4" title="V2 Features" icon={<SvgIcon name="list-icon" viewbox="0 0 27 21.375" />}>
              <Menu.Item key="m4-1" icon={<SvgIcon name="collateral-type" viewbox="0 0 100 99.479" />}>
                <NavLink
                  to="/collateral-type"
                  className={location.pathname === "/collateral-type" ? "selected" : ""}
                >
                  Collateral type
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m4-2" icon={<SvgIcon name="emode" viewbox="0 0 24 47" />}>
                <NavLink
                  to="/e-mode"
                  className={location.pathname === "/e-mode" ? "selected" : ""}
                >
                  E-mode
                </NavLink>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="m5" title="Risk Assessment" icon={<SvgIcon name="riskframework-icon" viewbox="0 0 30 28.451" />}>
              <Menu.Item key="m5-1" icon={<SvgIcon name="riskframework-icon" viewbox="0 0 30 28.451" />}>
                <NavLink
                  to="/risk-assessment"
                  className={location.pathname === "/risk-assessment" ? "selected" : ""}
                >
                  Risk Assessment
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m5-2" icon={<SvgIcon name="analysis-icon" viewbox="0 0 30 20.235" />}>
                <NavLink
                  to="/asset-risk-analysis"
                  className={location.pathname === "/asset-risk-analysis" ? "selected" : ""}
                >
                  Asset risk analysis
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m5-3" icon={<SvgIcon name="reservepool-icon" viewbox="0 0 33 27" />}>
                <NavLink
                  to="/reserve-pool"
                  className={location.pathname === "/reserve-pool" ? "selected" : ""}
                >
                  The Reserve Pool
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m5-4" icon={<SvgIcon name="shutdown-icon" viewbox="0 0 31.524 26.269" />}>
                <NavLink
                  to="/emergency-shutdown"
                  className={location.pathname === "/emergency-shutdown" ? "selected" : ""}
                >
                  Emergency shutdown
                </NavLink>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="m6" title="Using the platform" icon={<SvgIcon name="using-icon" viewbox="0 0 3.143 5.189" />}>
              <Menu.Item key="m6-1" icon={<SvgIcon name="lend" viewbox="0 0 30 30" />}>
                <NavLink
                  to="/lending-assets"
                  className={location.pathname === "/lending-assets" ? "selected" : ""}
                >
                  Lending Assets
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m6-2" icon={<SvgIcon name="farm" viewbox="0 0 30 30" />}>
                <NavLink
                  to="/withdrawing-assets"
                  className={location.pathname === "/withdrawing-assets" ? "selected" : ""}
                >
                  Withdrawing Assets
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m6-3" icon={<SvgIcon name="borrow" viewbox="0 0 30 30" />}>
                <NavLink
                  to="/borrowing-assets"
                  className={location.pathname === "/borrowing-assets" ? "selected" : ""}
                >
                  Borrowing Assets
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m6-4" icon={<SvgIcon name="swap" viewbox="0 0 30 30" />}>
                <NavLink
                  to="/repaying-assets"
                  className={location.pathname === "/repaying-assets" ? "selected" : ""}
                >
                  Repaying Assets
                </NavLink>
              </Menu.Item>
              <Menu.Item key="m6-5" icon={<SvgIcon name="assets" viewbox="0 0 30 30" />}>
                <NavLink
                  to="/bidding-for-assets"
                  className={location.pathname === "/bidding-for-assets" ? "selected" : ""}
                >
                  Bidding for Assets
                </NavLink>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="m7" icon={<SvgIcon name="governance-icon" viewbox="0 0 32.076 28.288" />}>
              <NavLink
                to="/governance"
                className={location.pathname === "/governance" ? "selected" : ""}
              >
                Governance
              </NavLink>
            </Menu.Item>
            <Menu.Item key="m8" icon={<SvgIcon name="contracts-icon" viewbox="0 0 28.5 24.429" />}>
              <NavLink
                to="/developers-docs"
                className={location.pathname === "/developers-docs" ? "selected" : ""}
              >
                Security and Developer Docs
              </NavLink>
            </Menu.Item>
            <Menu.Item key="m9" icon={<SvgIcon name="faq-icon" viewbox="0 0 33.846 33.846" />}>
              <NavLink
                to="/Faq"
                className={location.pathname === "/Faq" ? "selected" : ""}
              >
                FAQs
              </NavLink>
            </Menu.Item>
            <Menu.Item key="m10" icon={<SvgIcon name="strategies-icon" viewbox="0 0 45 38.5" />}>
              <NavLink
                to="/borrowing-strategies"
                className={location.pathname === "/borrowing-strategies" ? "selected" : ""}
              >
                Borrowing Strategies
              </NavLink>
            </Menu.Item>
            <Menu.Item key="m11" icon={<SvgIcon name="glossary-icon" viewbox="0 0 32 29" />}>
              <NavLink
                to="/glossary"
                className={location.pathname === "/glossary" ? "selected" : ""}
              >
                Glossary
              </NavLink>
            </Menu.Item>
          </Menu>
          <div className="social-footer">
            <Link to={{ pathname: "https://twitter.com/Commodo_Finance" }} target="_blank">
              <SvgIcon name="twitter" viewbox="0 0 25.617 20.825" />
            </Link>
            <Link to={{ pathname: "https://t.me/commodo_finance" }} target="_blank">
              <SvgIcon name="telegram" viewbox="0 0 24.635 20.66" />
            </Link>
            <Link to={{ pathname: "https://bit.ly/ComdexOfficialDiscord" }} target="_blank">
              <SvgIcon name="discord" viewbox="0 0 29.539 22.155" />
            </Link>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default SideBar;
