import React from "react";
import { Link } from "react-router-dom";
import "./index.less";

import LogoImage from "../../../assets/images/logo.svg";

const NavigationBar = () => {
	return (
		<>
			<div className="logo">
				<Link to="/">
				<img alt="LogoImage" src={LogoImage} />
				</Link>
			</div>
		</>
	);
};

export default NavigationBar;