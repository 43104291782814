import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

import StepImg20 from '../../assets/images/utp20.jpg';
import StepImg21 from '../../assets/images/utp21.jpg';

const BiddingforAssets = () => {
  return (
    <div className="using-platform-section">
      <h2>Bidding for Assets</h2>

      <h4>Steps:</h4>

      <p>1. Navigate to “Auction” from the left sidebar.</p>

      <img src={StepImg20} alt={StepImg20} />

      <p>
        2. Select the Auctioned Asset based on the list you wish to bid for and click on “Place Bid”.
      </p>

      <p>
        3. Enter your bid for the bidding asset.
      </p>

      <p>
        4. Enter the Acceptable max price and hit “Place Bid”.
      </p>

      <img src={StepImg21} alt={StepImg21} />

      <p>
        5. Keplr wallet will then prompt you to approve the transaction. You have successfully placed a bid and check its details in the Bidding History section.
      </p>

      <BottomNav
        preNavLink="repaying-assets"
        prevNavText="Repaying Assets"
        nextNavLink="governance"
        nextNavText="Governance"
      />

    </div>
  );
};

export default BiddingforAssets;