import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

import StepImg1 from '../../assets/images/utp1.jpg';
import StepImg2 from '../../assets/images/utp2.jpg';
import StepImg3 from '../../assets/images/utp3.jpg';
import StepImg4 from '../../assets/images/utp4.jpg';

const LendingAssets = () => {
  return (
    <div className="using-platform-section">
      <h2>Lending assets</h2>

      <h4>Steps:</h4>
      <p>
        1. Navigate to the “Lend” page and connect your wallet with Commodo. Once you click on “Keplr Wallet”, your wallet should pop up, click on “Approve” to connect the wallet.
      </p>

      <img src={StepImg1} alt={StepImg1} />

      <p>
        2. Click on the “Details” button of the Lend market in which you wish to deposit funds. On clicking it the corresponding lend market will open and you can choose the asset you wish to Lend. You can also see the current utilization of the asset and its Lend APY and other details from this screen.
      </p>

      <img src={StepImg2} alt={StepImg2} />

      <p>
        3. Once you select an asset you can see the “Available” amount you can lend. Now input the number of tokens you wish to lend(input a valid number) and click on the “Lend” button.
      </p>

      <p>
        For example, here the user is lending 1000 CMDX token
      </p>

      <img src={StepImg3} alt={StepImg3} />

      <p>
        4. Once you click on the “Lend” button, your wallet pops up, click on “Approve” to approve the transaction.
      </p>

      <p>
        5. If the transaction is successful, you will be redirected to the “My Home” page, where you can see the details of your lend position.
      </p>

      <img src={StepImg4} alt={StepImg4} />

      <BottomNav
        preNavLink="emergency-shutdown"
        prevNavText="Emergency Shutdown"
        nextNavLink="withdrawing-assets"
        nextNavText="Withdrawing Assets"
      />

    </div>
  );
};

export default LendingAssets;