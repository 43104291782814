import React from "react";
import { BottomNav } from "../../components/common";
import "./index.less";

const SecurityDeveloperDocs = () => {
  return (
    <div>
      <h2>Security and Developer Docs </h2>
      <h3>Abstract:</h3>
      <p>
        Lend module is used for collateralized lending-borrowing of assets on top of the Comdex chain. Any user can deposit their IBC-Assets in a listed cPool and start earning interest on the lent asset. Similarly, they can open a borrow position for the lent asset of different IBC-Asset. The module contains transactions to open/close/edit the Lend & Borrow position.
      </p>

      <p>
        If the user fails to maintain a proper collateralization ratio, then his borrow position will be liquidated. After liquidation creation of a borrow position for the same pair is restricted and then liquidation and auction are handled by the respective modules.
      </p>

      <h3>STATE:</h3>

      <h4>1. LendAsset: </h4>

      <pre>{`message LendAsset {
  uint64 lending_id 
  uint64 asset_id 
  uint64 pool_id 
  string owner 
  cosmos.base.v1beta1.Coin amount_in
  google.protobuf.Timestamp lending_time 
  string available_to_borrow 
  uint64 app_id
  string global_index
  google.protobuf.Timestamp last_interaction_time 
  string cpool_name
}`}
      </pre>

      <ol type="a">
        <li>lending_id: Unique Id for a lending Position</li>
        <li>asset_id: The IBC-Asset ID that which user deposits in the pool</li>
        <li>pool_id: The pool in which the user deposits the IBC-Asset</li>
        <li>owner: Owner of the Lend Position</li>
        <li>amount_in: The amount lent out in the pool</li>
        <li>lending_time: The time at which the asset was lent</li>
        <li>Available_to_borrow: The cToken representation of a lend position (lend rewards are added in this field)</li>
        <li>app_id: It is the app-specific ID for the Lending & Borrowing Platform</li>
        <li>global_index: The global interest index gives the current Lending rate of assets.</li>
        <li>last_interaction_time: This field is updated whenever a transaction is initiated updating the state of lendAsset. The time difference is used to calculate the rewards accumulated during the given time period.</li>
        <li>cpool_name: Name of the cPool in which the asset is lent</li>
      </ol>

      <p>	Users can come and lend their IBC-Assets in any of the pools according to their choice. The details for a particular asset loan are stored in this struct. Whenever a user deposits any IBC-Asset an equivalent amount of cToken is minted by the module and transferred to the user, which later is used to create a borrow position. Duplicate creation of a lent position for  IBC-Assets is prohibited in a specific pool. Available to borrow denotes the cToken balance of the user which is used to create a borrow position for different pairs available with the lent IBC-Asset in that pool.</p>

      <h4>2. BorrowAsset:</h4>

      <pre>
        {`message BorrowAsset {
 uint64 borrowing_id 
 uint64 lending_id 
 bool is_stable_borrow 
 uint64 pair_id 
 cosmos.base.v1beta1.Coin amount_in
 cosmos.base.v1beta1.Coin amount_out 
 cosmos.base.v1beta1.Coin bridged_asset_amount 
 google.protobuf.Timestamp borrowing_time 
 string stable_borrow_rate 
 string interest_accumulated 
 string global_index 
 string reserve_global_index 
 google.protobuf.Timestamp last_interaction_time 
 string cpool_name
 bool is_liquidated 
}
`}
      </pre>

      <ol type="a">
        <li>borrowing_id: Unique Id for a Borrow Position</li>
        <li>lending_id: Lending Position ID by which we want to open a borrow position</li>
        <li>is_stable_borrow: It is to differentiate if the borrow position is for normal rates or the stable borrow rate.</li>
        <li>pair_id: It is the pair of IBC-Asset that we want to borrow against the lent position Asset</li>
        <li>amount_in: It is the cAsset Amount which we are using to create a Borrow against it.</li>
        <li>amount_out: It is the IBC_Asset which we are borrowing against the cToken we deposited</li>
        <li>bridged_asset_amount: It is the bridged asset which is transferred from one pool to another if there is any Inter-cPool Borrowing. If not then the bridged_asset_amount.The amount will be 0</li>
        <li>borrowing_time: Time at which the IBC-Asset was Borrowed</li>
        <li>stable_borrow_rate: If the borrow pos was a stable borrow then the stable_borrow_rate will be stored at the time of creating the borrow Position else it will be 0</li>
        <li>interest_Accumulated: It is the interest accumulated on the borrowed asset</li>
        <li>global_index: The global interest index gives the current borrowing rate of assets.</li>
        <li>Reserve_global_index: This parameter is similar to the global_index, the only difference here is that it is used for calculating the amount going to the reserve pool. </li>
        <li>last_interaction_time: This field is updated whenever a transaction is initiated updating the state of borrowAsset. The time difference is used to calculate the rewards accumulated during the given time period.</li>
        <li>cpool_name: Name of the cPool in which the asset is Borrowed</li>
      </ol>

      <p>
        A borrow position is created using cTokens which are available to borrow for a given lend position. The user first selects the loan position for which he wants to borrow. After selecting the lend position he will have the option to choose pairs from asset_pair_mappings data. The user selects the pair and then enters the cToken he wants to open a borrow position for. The maximum amount that can be borrowed is determined by the LTV of the lent IBC-Asset (from asset_rates_stats data). As soon as the user borrows some  amount Interest starts getting accumulated for his borrow position. Whenever the user closes his position he has to return Amount Borrowed plus the interest accumulated.
      </p>

      <h4>3. Pool:</h4>

      <pre>
        {`message Pool {
 uint64 pool_id 
 string module_name 
 string cpool_name 
 uint64 reserve_funds 
 repeated AssetDataPoolMapping asset_data 
}
 
message AssetDataPoolMapping{
 uint64 asset_id 
 uint64 asset_transit_type
 uint64 supply_cap
}
`}
      </pre>

      <ol>
        <li>pool_id: Unique Id for a Pool</li>
        <li>module_name: Module Account name for that pool</li>
        <li>cpool_name: Name of the cPool</li>
        <li>reserve_funds: represents the lower limit token value of the pool, checked before borrowing an asset.</li>
        <li>AssetDataPoolMapping: represents asset-specific data for assets present in a cPool
          <ol type="i">
            <li>asset_id: represents the assetID for which data are mapped in this field</li>
            <li>asset_transit_type : represents the type of asset. Ie,. main asset or transit asset. ( 1 for main_asset, 2 for 1st transit_asset, 3 for 2nd transit_asset )</li>
            <li>supply_cap: defines the upper limit of an asset value($ value ) above which a user can not lend/deposit specified asset in that cPool </li>
          </ol>
        </li>
      </ol>

      <p>	A pool stores all the IBC-Assets in a specific module account. One pool contains main Asset and Transit assets. Lend and Borrow operations can be done either on one pool or different pools. In the case of cross borrow (lent asset is on one pool and borrowed asset is from a different pool) then the first_transit_asset_id is used as a transition between different pools. Only if there is insufficient liquidity for the transaction from the first transit asset, the second transit asset is used for transition into the second pool.
      <br />
      The LTV calculations of bridged assets are also taken into consideration while doing a cross-borrow. The pools are initially bootstrapped from the fund_module_account transaction.
      </p>

      <h4>4. Extended_Pair:</h4>

      <pre>
        {`message Extended_Pair {
 uint64 id 
 uint64 asset_in 
 uint64 asset_out 
 bool is_inter_pool 
 uint64 asset_out_pool_id 
 uint64 min_usd_value_left 
}
`}
      </pre>

      <ol type="a">
        <li>id: Unique Id for a Pair</li>
        <li>asset_in: Asset In ID</li>
        <li>asset_out: Asset Out ID</li>
        <li>is_inter_pool: Is the pair allow InterPool borrowing</li>
        <li>asset_out_pool_id: Pool ID from which the Asset is borrowed</li>
        <li>min_usd_value_left: params for dust calculation for Auction</li>
      </ol>

      <p>
        An Extended Pair defines the assetIn and assetOut according to the pools. This is used while creating a borrow transaction. It also has min_usd_value_left which determines the dust calculations in the auction.
      </p>

      <h4>5. AssetToPairMapping:</h4>

      <pre>
        {`message AssetToPairMapping{
 uint64 asset_id 
 uint64 pool_id 
 repeated uint64 pair_id 
}
`}
      </pre>

      <p>
        This maps the asset In ID and Pool ID for a list of pair IDs
        The user can only use the IDs listed if he wants to borrow against a lend position. This info are added through a base governance proposal.
      </p>

      <h4>6. UserAssetLendBorrowMapping</h4>

      <pre>
        {`message UserAssetLendBorrowMapping{
 string owner ;
 uint64 lend_id 
 uint64 pool_id 
 repeated uint64 borrow_id
}
`}
      </pre>

      <p>
        This keeps a track of all the data of a specific user, different data can be queried by using different keys. Data is stored by using a different combination of keys for getting the required queries.
      </p>

      <h4>7. PoolAssetLBMapping:</h4>

      <pre>
        {`message PoolAssetLBMapping{
 uint64 pool_id 
 uint64 asset_id 
 repeated uint64 lend_ids
 repeated uint64 borrow_ids
 string total_borrowed 
 string total_stable_borrowed 
 string total_lend 
 string total_interest_accumulated 
 string lend_apr 
 string borrow_apr 
 string stable_borrow_apr 
 string utilisation_ratio 
}
`}
      </pre>

      <p>	For a given poolID and assetID we can get all the data of that specific asset in that pool.</p>

      <ol type="a">
        <li>lend_ids: stores lendIDs for the protocol for a specific poolID and assetID</li>
        <li>borrow_ids: stores borrowIDs for the protocol for a specific poolID and assetID</li>
        <li>total_borrowed: represents the total borrowed asset qty from that pool</li>
        <li>total_stable_borrowed: represents the total stable borrowed asset qty from that pool (not all assets can be used for stable borrow, only assets having stable_borrow enabled will be able to borrow against stable rates )</li>
        <li>total_lend: represents the total lend asset qty from that pool</li>
        <li>Total_interest_accumulated: this parameter is updated when a user repays his interest. Some part of it goes to reservePool and the remaining goes back to the cPool. This amount is then given to the lenders as the lend_rewards.</li>
        <li>Lend_apr, borrow_apr, stable_borrow_apr and utilisation_ratio show the current statistics of the specific asset in the cPool</li>
      </ol>

      <h4>8. AssetRatesStats:</h4>

      <pre>
        {`message AssetRatesParams{
 uint64 asset_id 
 string u_optimal 
 string base 
 string slope1 
 string slope2 
 bool enable_stable_borrow 
 string stable_base
 string stable_slope1 
 string stable_slope2 
 string ltv 
 string liquidation_threshold 
 string liquidation_penalty 
 string liquidation_bonus 
 string reserve_factor 
 uint64 c_asset_id 
}
`}
      </pre>

      <p>Asset Rates Stats stores the relevant constants used for lending and borrowing APR calculations of a particular IBC-Asset.</p>

      <pre>
        {`message StableBorrowMapping{
 repeated uint64 stable_borrow_ids }`}
      </pre>

      <p>	Represents the total stable borrowed borrowPosition existing in the protocol.</p>

      <h4>9. ReserveBuybackAssetData :</h4>

      <pre>
        {`message ReserveBuybackAssetData{
 uint64 asset_id 
 uint64 reserve_amount
 uint64 buy_back_amount
}
`}
      </pre>

      <p>It stores the protocol earning statistics. Whenever a user repays his borrow interest, a part of this is sent to the lend ModuleAccount. For example, If the protocol earns 10tokens then the updated states are as follow: reserve_amount {`-> 5tokens, buy_back_amount ->`} 5 tokens. The buy_back_amount will be used to buy more cmdx Tokens from the protocols earning. In the case of dutch_auctions, when the price falls down and the protocol has to bear losses, which are covered by this reserve amount.</p>

      <h4>10. AuctionParams:</h4>

      <pre>
        {`message AuctionParams{
   uint64 app_id 
   uint64 auction_duration_seconds
   string buffer 
   string cusp 
   string step 
   uint64 price_function_type 
   uint64 surplus_id 
   uint64 debt_id 
   uint64 dutch_id 
   uint64 bid_duration_seconds
}
`}
      </pre>

      <p>Auction Prams:</p>

      <ol type="a">
        <li>App_id: unique app id</li>
        <li>Auction_duration_seconds: sets auction duration</li>
        <li>Buffer: The ratio of the initial price to start the auction with.</li>
        <li>Cusp: The ratio of the initial price to the end of the auction.</li>
        <li>Step: Length of time between price drops</li>
        <li>Price_function_type: defines the price function type used for decreasing auction</li>
        <li>Surplus_id: sets the surplus auction id</li>
        <li>Debt_id: sets the surplus debt id</li>
        <li>Dutch_id: sets the dutch auction id</li>
        <li>Bid_duration_seconds: defines the bid duration between 2 bids for which the auction should be active</li>
      </ol>

      <p>Messages</p>

      <pre>
        {`message MsgLend {
 string                   lender
 uint64                   asset_id 
 cosmos.base.v1beta1.Coin amount
 uint64                   pool_id 
 uint64                   app_id 
}
`}
      </pre>

      <p>MsgLend:</p>

      <ol type="a">
        <li>A user can come and create their own Lend Position using MsgLend.</li>
        <li>The user provides the assetID, poolID and the amount. After the tx is complete a new lend position is created for that user with a unique lendID.</li>
        <li>A cToken representative of the IBC-Asset is minted and then transferred to the user which denotes the borrowing power/ Available to borrow capacity.</li>
        <li>Rewards start to get accumulated on the lent Amount if the lendingApr is above 0 and sent to the user in cToken. Available to borrow is also increased accordingly.</li>
      </ol>

      <pre>
        {`message MsgDeposit {
 string                   lender
 uint64                   lend_id 
 cosmos.base.v1beta1.Coin amount ;
}
`}
      </pre>

      <p>MsgDeposit:</p>

      <ol type="a">
        <li>A user can add further IBC-assets to their existing lend position by the Msgdeposit Txn.</li>
        <li>As the tx is done the Amount goes to the respective cPool Module account and the user’s Available to borrow is updated.</li>
        <li>Additional cToken is provided to the user.</li>
      </ol>

      <pre>
        {`message MsgWithdraw {
 string                   lender 
 uint64                   lend_id
 cosmos.base.v1beta1.Coin amount 
}
`}
      </pre>

      <p>MsgWithdraw:</p>

      <ol type="a">
        <li>A user can withdraw further IBC-assets to their existing lend position by the Msgdeposit Txn.</li>
        <li>A user enters the amount to withdraw and then the equivalent amount of cToken is transferred to the module account of the respective cPool and finally burned.</li>
        <li>Available to borrow is updated accordingly.</li>
        <li>The max amount that can be withdrawn is limited to available to borrow.</li>
      </ol>

      <pre>
        {`message MsgCloseLend {
 string                   lender
 uint64                   lend_id 
}
`}
      </pre>

      <p>MsgCloseLend:</p>
      <ol type="a">
        <li>When a user wishes to close his lend position this tx can do.</li>
        <li>The updated Amount of cToken is taken from the user’s account and an equivalent amount of IBC-Asset initially lent is returned back to the user from the cPool Module Account.</li>
        <li>Lend Position for that user is closed.</li>
        <li>If any borrow position is open for the specific lend position, then we can’t close that lend position.</li>
      </ol>
      
      <pre>
        {`message MsgBorrow {
 string                   borrower;
 uint64                   lend_id;
 uint64                   pair_id;
 bool                     is_stable_borrow;
 cosmos.base.v1beta1.Coin amount_in ;
 cosmos.base.v1beta1.Coin amount_out ;
}
`}
      </pre>

      <p>MsgBorrow:</p>
      <ol type="a">
        <li>The user Inputs the lendID, AmountIn(in terms of cToken), AmoutOut(in terms of IBC-Asset), pairID and Stable Borrow check.</li>
        <li>If all the criteria are satisfied then a new borrow position is created.</li>
        <li>Lend to borrow mapping is updated and available to borrow is reduced by the amountIn token amount.</li>
        <li>Interest starts to get accumulated for the borrow position for the borrowed amount.</li>
      </ol>

      <pre>
        {`message MsgRepay {
 string                   borrower;
 uint64                   borrow_id;
 cosmos.base.v1beta1.Coin amount ;
}
`}
      </pre>

      <p>MsgRepay:</p>
      <ol type="a">
        <li>A User can Repay the Borrowed Amount by using MsgRepay txn.</li>
        <li>However, the user can’t close the lend position by Msgrepay Txn.</li>
        <li>After the Repayment total borrowed is updated.</li>
        <li>Whenever a user repays, first his interest is settled and then the borrowed amount is reduced.</li>
      </ol>

      <pre>
        {`message MsgDepositBorrow {
 string                   borrower;
 uint64                   borrow_id;
 cosmos.base.v1beta1.Coin amount ;
}
`}
      </pre>

      <p>MsgDepositBorrow:</p>
      <ol type="a">
        <li>This Txn is similar to Msgdeposit. </li>
        <li>Here a user can deposit an additional amount of cToken to his borrowed position to maintain the collateralization ratio.</li>
        <li>AmountIn is updated after the transaction is done.</li>
        <li>Available to borrow is also updated after the transaction is done.</li>
      </ol>

      <pre>
        {`message MsgDraw {
 string                   borrower 
 uint64                   borrow_id
 cosmos.base.v1beta1.Coin amount 
}
`}
      </pre>

      <p>MsgDraw:</p>
      <ol type="a">
        <li>If a user wants to take out further IBC-Asset from his borrow position maintaining the collateralisation ratio, then this txn is used.</li>
        <li>Additional IBC-Assets are given to the user from the cPool moduleAccount.</li>
        <li>The Borrow position is updated accordingly.</li>
      </ol>

      <pre>{`message MsgCloseBorrow {
 string                   borrower ;
 uint64                   borrow_id ;
}
`}</pre>

      <p>MsgCloseBorrow:</p>

      <ol type="a">
        <li>This txn is similar to the MsgCloseLend Txn.</li>
        <li>The updated IBC-Asset Amount is taken from the user’s account (amout out + interest accumulated on that) and sent to the cPool module account.</li>
        <li>The cTokens are sent back to the user’s account.</li>
        <li>The borrow Position is deleted from the KV Store and also Available to Borrow is updated.</li>
        <li>If the user doesn't have sufficient funds then the txn fails.</li>
      </ol>

      <pre>
        {`message MsgBorrowAlternate {
 string                   lender ;
 uint64                   asset_id ;
 uint64                   pool_id ;
 cosmos.base.v1beta1.Coin amount_in ;
 uint64                   pair_id ;
 bool                     is_stable_borrow ;
 cosmos.base.v1beta1.Coin amount_out ;
 uint64                   app_id ;
}
`}
      </pre>

      <p>MsgBorrowAlternate:</p>
      <ol type="a">
        <li>This txn is a combination of lend and borrow.</li>
        <li>The user can create a borrow position directly by lending the IBC-Assets using the lend position.</li>
        <li>The lent IBC-Assets are converted to corresponding cAssets and using them as the deposit token a borrow position is opened. </li>
        <li>The user can edit their lend and borrow position individually thereafter.</li>
      </ol>

      <pre>
        {`message MsgFundModuleAccounts {
 string                   moduleName;
 uint64                   assetId;
 string                   lender;
 cosmos.base.v1beta1.Coin amount ;
}
`}
      </pre>

      <p>MsgFundModuleAccounts:</p>
      <ol type="a">
        <li>This txn is used to fund the cPools Module account to bootstrap the liquidity for the protocol.</li>
        <li>AssetId, cPool Module name and Amount are provided by the user. After the txn the specified amount is sent to the module account from the user’s account.</li>
      </ol>

      <BottomNav
        preNavLink="governance"
        prevNavText="Governance"
        nextNavLink="Faq"
        nextNavText="Faq"
      />

    </div>
  );
};

export default SecurityDeveloperDocs;